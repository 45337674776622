import React , {useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Row, Col,  Card, Button, Form, InputGroup } from 'react-bootstrap'
import DateFormatted from '../../DateFormatted'

//import {addEquationQs, deleteEquationQs, updateEquationQs} from '../../actions/qsActions'



import Message from '../../../components/Message'

// import { Node, Context } from 'react-mathjax';
//import MathJax from 'react-mathjax';

export const QsEquationView = ({mode_in, question_id, mcq_id, equation}) => {


    const dispatch = useDispatch()

    const [mode, setMode] = useState(mode_in) //'edit'

    const [eqText, setEqText] = useState(``)

    const genEq = (text_in) => {

        const modText = `${text_in}`
        setEqText(modText)

    }

    useEffect(() => {
        //getQandA()

        if(equation){
            setEqText(equation.text)
        }


    }, [equation])


    const submitEquation = (e) => {
        e.preventDefault()

        console.log('Submit Equation ', eqText)

        //dispatch(addEquationQs(question_id, eqText))
    }


    const updateEquation = (e) => {
        e.preventDefault()
        //dispatch(updateEquationQs(question_id, equation._id, eqText))
    }


    const deleteEquation = (e) => {
        e.preventDefault()

        //dispatch(deleteEquationQs(question_id, equation._id))
    }



    const tex = `f(x) = \\int_{-\\infty}^\\infty
    \\hat f(\\xi)\\,e^{2 \\pi i \\xi x}
    \\,d\\xi`


    return (

        <Fragment>

        

        {mode_in == 'view' && equation && equation.text && (

            <Card style={{display:'flex', justifyContent:'left', border:'None'}}>
            {/* <MathJax.Provider>
                <div style={{display:'flex', justifyContent:'left'}}>
                    <MathJax.Node formula={equation.text}/>
                </div>
            </MathJax.Provider> */}
            </Card>
        )}




        {mode_in == 'edit' && (

            <Fragment>

                {/* <Message>Enter the equation or expression in latex form in the second box below and click the tick button to update or click the trash icon to delete the equation.</Message> */}

                {/* <Card>
                    <MathJax.Provider>
                        <div>
                            <MathJax.Node formula={eqText} />
                        </div>
                    </MathJax.Provider>
                </Card> */}
                


            <Row style={{marginTop:'5px'}}>
                <Col md={9}>
                    <Form.Group controlId='equation'>
                        {/* <Form.Label>Enter your mathematical expression below in latex form, it should show up above as mathematical symbols</Form.Label> */}
                        <Form.Control as='textarea' style={{backgroundColor:'white'}} type='text' placeholder='Enter Equation in Latex form here' value={eqText} onChange={(e) =>  genEq(e.target.value)}> 
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Col md={3}>

                    <Button onClick={updateEquation} className="button_qanda" >
                        <i class="fas fa-check"></i>
                    </Button>

                    <Button onClick={deleteEquation} className="button_qanda" >
                        <i class="fas fa-trash"></i>
                    </Button>
                </Col>

            </Row>





            </Fragment>


        )}



        {mode_in == 'create' && (

        <Fragment>


        <Message>Enter the equation or expression in latex form in the second box below and click the 'ADD' button to add a mathematical expression to the question.</Message>


            <Card>
                {/* <MathJax.Provider>
                    <div>
                        <MathJax.Node formula={eqText} />
                    </div>
                </MathJax.Provider> */}
            </Card>
            


        <Row>
            <Col md={9}>
                <Form.Group controlId='equation'>
                    {/* <Form.Label>Question</Form.Label> */}
                    <Form.Control  style={{backgroundColor:'white'}} type='text' placeholder='Enter Equation in Latex form here' value={eqText} onChange={(e) =>  genEq(e.target.value)}> 
                    </Form.Control>
                </Form.Group>
            </Col>

            <Col md={2}>

                <Button onClick={submitEquation} className="button_qanda" >
                    Add
                </Button>
            </Col>

        </Row>





        </Fragment>


        )}





        </Fragment>
    )
}


export default QsEquationView
