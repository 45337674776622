import React, {useState, useEffect, Fragment} from 'react'
import { ListGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'



const EulaScreen = ( {} ) => {

    const dispatch = useDispatch()


    useEffect(() => {

        

    }, [])

    return (

        <Fragment>

        <h1>TERMS AND CONDITIONS</h1>

        <h5>Effective date: September 23, 2022</h5>


        <ListGroup style={{border:'None'}}>

            <ListGroup.Item style={{border:'None'}}>

        <p>
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY USING THIS WEBSITE AND/OR PLATFORM YOU AGREE TO BE BOUND BY ALL OF THE BELOW TERMS AND CONDITIONS AND PRIVACY POLICY.
Pixagan Technologies Private Limited ("Company", “We”, “Our” or “Us” and their connotations) operates a website (https://www.teachyaar.com/) and a mobile application called TeachYaar (collectively referred to as “Platform”) which is engaged in providing the service of providing online classes and tutoring to Users. The Users of the Platform are referred to as “You”, as per the context as may be applicable in these Terms. The term "User(s)" for the purposes of these Terms shall be read as the end users and shall mean anyone who uses the Platform.
These terms and conditions (“Terms”) describe the terms on which the Company grants You access to the Platform (hereinafter referred to as “Services”) and shall be read with the privacy policy available on https://www.teachyaar.com/privacypolicy (“Privacy Policy”).
By using and signing up on this Platform, You acknowledge and confirm that You are 18 years of age or older, and if You are under 18 years of age (i.e. “a minor”), Your usage of the Platform shall be subject to the consent, supervision and moderation by a parent or legal guardian. If the Company has previously prohibited You from accessing or using the Platform, You are not permitted to access or use the same. The Company shall not be liable for any unauthorised use and/or access of the Platform and/or Services. 
</p>
</ListGroup.Item>




<ListGroup.Item style={{border:'None'}}>
<h5>1. TERMS OF SERVICE</h5>
By choosing to visit and/ or avail any Services provided by Company (including without limitation accessing the Platform), You agree to these Terms and the Privacy Policy (as may be amended from time to time). Please read the following information carefully. By your continued access or use of the Platform, You signify your agreement to be legally bound by the Terms set forth herein. If You do not agree to these Terms, You are requested to promptly exit this page and stop accessing the Services.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>2. SYSTEM REQUIREMENTS</h5>
Use of the Services requires one or more compatible devices, internet access (fees may apply), and certain software (fees may apply), and may require obtaining updates or upgrades from time to time. Due to the fact that use of the Services involves hardware, software, and internet access, your ability to access and use the Services may be affected by the performance of these factors. High speed internet access is recommended. You acknowledge and agree that such system requirements, which may be changed from time to time, are your responsibility.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>3. DESCRIPTION OF SERVICES</h5>
The Platform provides online training and tutoring services to Users. You hereby understand that the content posted and the details listed on the Platform are provided for educational purposes only and “as-is”. While we endeavour to ensure that the quality and accuracy of the information available on the Platform are up to best standards, We make no guarantees with respect to the quality or accuracy of the Services. 
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>4. LICENSE TO USE</h5>
The Company hereby grants to the Users, the limited right to access, view and use the Platform for the purposes of accessing and viewing the content posted thereon. Company reserves the right to suspend or deny, in its sole discretion, your access to all or any portion of the Platform. This license is limited to personal and non-commercial uses by the Users. Any rights not expressly granted to You herein are reserved to Company.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>5. INTELLECTUAL PROPERTY RIGHTS</h5>
The Company retains all rights (including copyrights, trademarks, patents, designs, logos, trade-dress, trade-secrets, know-how as well as any other intellectual property right) in relation to all information provided on or via this Platform by the Company (including but not limited to all texts, graphics, photos, illustrations, apps and logos). You shall not copy, download, publish, distribute or reproduce any of the information contained on this Platform or social media in any form without the prior written consent of the Company.
</ListGroup.Item>


<ListGroup.Item style={{border:'None'}}>
<h5>6. RULE OF CONDUCT</h5>
Users must comply with the laws that apply to You in the location that You access the Company’s Services from. If any laws applicable to You restrict or prohibit You from using the Services of the Company, You must comply with those legal restrictions or, if applicable, stop accessing and/or using the Services of the Company. Notwithstanding any other provision of these Terms, You agree and undertake not to:
<ul>
<li>
    Hack, attempt to hack, modify, adapt, merge, translate, decompile, disassemble, reverse engineer or create derivative works out of the Service or any part of them (save to the extent which expressly cannot be prohibited in accordance with the applicable mandatory law in your jurisdiction);
</li>
<li>
    Remove, disable, modify, add to or tamper with any program code or data, copyright, trademark or other proprietary notices and legends contained on or in the Service;
</li>
<li>
Create software which mimics any data or functionality in the Service;
</li>
<li>
Use or deal in the Service except as permitted by these Terms;
</li>
<li>
Use your access to the Service, or information gathered from it, for the sending of unsolicited bulk email;
</li>
<li>
Provide hypertext links, URL links, graphic links, hyperlinks or other direct connection for profit or gain to the Service without prior written permission of Company;
</li>
<li>
Make the Service or any part of it available to any third party (please note this does not stop you from fairly and honestly providing links to the Platform, or showing either to other people);
</li>
<li>
Use or process the Service or any part of them unfairly or for any illegal or immoral purpose; or
</li>
<li>
Delete or obscure any copyright or other proprietary notice on the Service.
</li>
</ul>
The Service may only be accessed and used via authorized servers. You must take no action to bypass authorized servers and/or use third party software to modify any aspect of the Service, whether for the purpose of securing an unfair advantage over other users, and You expressly consent to Company’s monitoring your computer's random-access memory for the purpose of identifying said unauthorized third-party programs.
</ListGroup.Item>


<ListGroup.Item style={{border:'None'}}>
<h5>7. LIMITATION OF LIABILITY</h5>
You agree to reimburse the Company for all damages resulting from the culpable non-observance of the obligations of these Terms. The Company does not guarantee or warrant accuracy, adequacy, correctness, validity, completeness, or suitability for any purpose, and accepts no responsibility with respect to the information given on the Platform. While the Company shall ensure, to the best of its abilities, that the links on its Platform shall be secure, except as expressly set forth in these Terms, the Services and implementation of the Services are provided “as is” and the Company disclaims all warranties, express or implied in relation thereto. In no event shall the Company be liable for any direct, indirect, incidental, special or consequential damages, or damages for loss of profits, revenue, data or data use, incurred by You or any third-party, whether in an action in contract or tort, arising from your access to, or use of, the Platform or any content provided on or through the Platform. Any third party websites and online services accessible by the Users through a hyperlink provided on the Platform (“External Sites”) have not been verified or reviewed by Company and all use and access of External Sites is made at your own risk. We recommend perusing the terms of service and privacy policy of such third party websites prior to accessing the same. The Company gives no other warranty in connection with the Service and to the maximum extent permitted by law, Company excludes liability for:
<ul>
<li>
any loss or damage of any kind howsoever arising, including any direct, indirect, special, punitive or consequential loss whether or not such arises out of any problem which Company have been made aware of;
</li>
<li>
the accuracy, currency or validity of information and material contained within the Service;
</li>
<li>
any interruptions to or delays in updating the Service;
</li>
<li>
any incorrect or inaccurate information on the Service;
</li>
<li>
the infringement by any person of any copyright or other intellectual property rights of any third party through use of the Service;
</li>
<li>
the availability, quality, content or nature of External Sites;
</li>
<li>
any transaction involving External Sites;
</li>
<li>
any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using the Service; and
</li>
<li>
all representations, warranties, conditions and other terms and conditions which but for this notice would have an effect.
</li>
</ul>
The Company does not warrant that the operation of the Service will be uninterrupted or error-free. The Company will not be liable in any amount for failure to perform any obligation under these Terms if such failure is caused by the occurrence of any unforeseen contingency beyond the reasonable control of the Company including Internet outages, communications outages, fire, flood, war or act of God. Except as provided above there are no other warranties, conditions or other terms and conditions, express or implied, statutory or otherwise, and all such terms and conditions are hereby excluded to the maximum extent permitted by law. You agree that in relation to your use of the Service, You will not in any way conduct yourself in a manner which is unlawful, or which gives rise to civil or criminal liability or which might call Company or the Service into disrepute. 
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>8. AMENDMENTS TO TERMS</h5>
The Company retains the right to modify these Terms: 
<ul>
    <li>
(a) in the event of any amendments made due to legal changes, 
</li>
<li>
(b) in the event of any amendments made due to decisions by the authorised court having competent jurisdiction, 
</li>
<li>
(c) because of technical necessity, 
</li>
<li>
(d) in order to maintain, update and modify Company operations, 
</li>
<li>
(e) in the event of a change in market conditions,
</li>
<li>
(f) for the benefit of the User 
</li>
<li>
(g) in the event of any update in the Company’s internal policies. No amendment will take place if such amendment would substantially disrupt the contractual balance between the parties. The Users will be informed of any amendments to the general Terms by way of an update to the Terms available on the Platform. You are advised to check the Terms on the Platform from time to time.
</li>
</ul>
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>9. TERMINATION</h5>
Without limiting any other rights that the Company may have, the Company may remove, restrict, cancel or suspend your access to and/or use of the Service and any part of it, if the Company considers (in the sole discretion of Company) that You have breached any of these Terms. Please note that if your access to the Services has been removed, restricted, cancelled or suspended, We do not have an obligation to restore the same unless otherwise required under applicable law. You may also terminate your agreement with the Company by ceasing to access the Service. Termination shall not affect any rights or remedies, which have accrued up to the time of termination.
</ListGroup.Item>


<ListGroup.Item style={{border:'None'}}>
<h5>10. INDEMNITY</h5>
You agree to indemnify and hold us, Our shareholders, contractors, and Our licensors, and our respective directors, officers, employees and agents harmless from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Services and/or the Platform, including but not limited to the violation of these Terms by You.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>11. SEVERABILITY</h5>
If any court or competent authority finds that any provision of these Terms (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these Terms shall not be affected.
</ListGroup.Item>


<ListGroup.Item style={{border:'None'}}>
<h5>12. ASSIGNMENT</h5>
Any rights and licenses granted hereunder shall not be transferred or assigned by You but, shall be assigned by the Company. Any attempted transfer or assignment in violation hereof shall be considered as null and void.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>13. WAIVER</h5>
No waiver of any terms of these Terms shall be treated as a further or continuing waiver of such term or any other term and the Company’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>14. GOVERNING LAW AND JURISDICTION</h5>
These Terms shall be governed by and construed in accordance with the laws of India, which are not in conflict with each other. Any and all such disputes arising out of or in relation to these Terms shall be subjected to the exclusive jurisdiction of the competent courts of Kochi, Kerala, India.
</ListGroup.Item>

<ListGroup.Item style={{border:'None'}}>
<h5>15. FEEDBACK</h5>
We welcome any and all kind of feedback towards Our Platform. You may share your feedback by writing to us at the below-mentioned email address. Any feedback You may provide with respect to the Platform shall be deemed to be non-confidential. The Platform shall be free to use such information on an unrestricted basis. Further, by submitting the feedback, You represent and warrant that 
<ul>
    <li>
(i) Your feedback does not contain confidential or proprietary information of You or of third parties; 
</li>
<li>
(ii) the Company is not under any obligation of confidentiality, express or implied, with respect to the feedback; 
</li>
<li>
(iii) the Platform may have something similar to the feedback already under consideration or in development; 
</li>
and 
<li>
(iv) You are not entitled to any compensation or reimbursement of any kind from the Company for the feedback under any circumstances, unless specified. 
</li>
</ul>
</ListGroup.Item>


<ListGroup.Item style={{border:'None'}}>
<h5>16.	Contact </h5>
<p>
If you have any questions about these Terms, please contact us by email or postal mail on the following address:</p>

<p>Company: Pixagan Technologies Private Limited</p>
<p>Name: Anil Variyar</p>
<p>E-mail ID: biz@pixagan.com</p>
<p>Address: 39/2475-B1, Suite#244</p>
<p>LR Towers, SJRRA 104,</p>
<p>S Janatha Road, Palarivattom,</p>
<p>Kochi, Kerala, 682025</p>

</ListGroup.Item>
</ListGroup>

        </Fragment>
    )
}


export default EulaScreen
