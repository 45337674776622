import axios from 'axios';
import { setAlert } from './alertActions';

import {

    UPDATE_SESSION_TITLE,
    SESSION_TITLE_FAIL,
    SESSION_VIEW_MODE,
    CLEAR_SESSION,
    CLEAR_CARDS,

    CARD_LIST,
    CARDS_FAIL

} from '../constants/cardConstants'


import {

    SHELVES_LIST,
    SHELVES_FAIL,
    LOAD_NOTEBOOK,
    NOTEBOOK_FAIL

} from '../constants/learnConstants'



export const loadShelves = (  ) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/notebooks`

        const { data } = await axios.get(full_url,config)

        
        dispatch({
            type: SHELVES_LIST,
            payload: data
        })

    }catch (error){

        dispatch({
            type: SHELVES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




export const loadNotebookById = ( notebook_id ) => async (dispatch, getState) => {

    try{

        
        console.log("Update Title")


        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/notebooks/${notebook_id}`

        const { data } = await axios.get(full_url,config)


        console.log("Dat ", data)
        

        dispatch({
            type: LOAD_NOTEBOOK,
            payload: data
        })


    }catch (error){

        dispatch({
            type: NOTEBOOK_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}
