import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import QuestionItem from './QuestionItem'

import QuestionItemMenu from './QuestionItemMenu'


export const TestItem = ({card_in}) => {

    const dispatch = useDispatch()

    const notebookR = useSelector(state => state.notebookR)
    const { sessionMode } = notebookR

    useEffect(() => {
        
        
    }, [])


    return (

        <>

            <div>

            </div>



            <div>

                <ListGroup>

                    {card_in.questions && card_in.questions.map((cquestion, index)=>(

                        <ListGroup.Item>

                            <QuestionItem card_id={card_in._id} question={cquestion}/>

                        </ListGroup.Item>

                    ))}
                

                        {sessionMode == 'edit' && (
                            <ListGroup.Item>
                            <QuestionItemMenu card_id={card_in._id}/>
                        </ListGroup.Item>
                        )}
                        

                </ListGroup>


            </div>


            

            
        
        
        </>

        
    )
}


export default TestItem
