import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'


export const SmartLearnCardOne = ({index}) => {

    const dispatch = useDispatch()


    const [tagC, setTagC ] = useState('')

    const [textQuery, setTextQuery ] = useState('')

    const [addTagtoggle, setaddTagtoggle ] = useState(false)
    const toggleAddTag = () => {
        setaddTagtoggle(addTagtoggle => !addTagtoggle)
    }





    useEffect(() => {
        


        
    }, [])




    return (

        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
            <Card.Header style={{border:'solid', margin:'3px', borderRadius:'10px'}}>

            <Badge className='card_badge' style={{padding:'5px', marginBottom:'5px', color:'white', borderRadius:'5px'}} ><span className='h5' style={{color:'white'}}>Getting Started with TeachYaar Snippets</span></Badge>

            <ListGroup>
                <ListGroup.Item style={{border:'None', backgroundColor:'inherit'}}>
                    <Card style={{border:'None', backgroundColor:'inherit'}}>
                        <p className='h5' style={{color:'black', textDecoration: 'underline', fontFamily:'Comic Sans MS, Comic Sans, cursive'}}>Search for Concepts, Examples and Tests</p>
                        <ul>
                            <li className='h5'>Search for concepts in the topics section</li>
                            <li className='h5'>Currently Featuring English and Maths Topics</li>
                            <li className='h5'>Try out examples by clicking</li>
                            <li className='h5'>Make the most of our Smart Question Bank by asking for custom Tests</li>
                        </ul>

                    </Card>
                </ListGroup.Item>


            </ListGroup>




            </Card.Header>

            

        </Card>
    )
}


export default SmartLearnCardOne
