import React, { Fragment, useEffect } from 'react' 
import {  Link, useNavigate} from 'react-router-dom'
//import {  useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {Navbar, Nav, Container, NavDropdown, Img, Button, DropdownButton, Dropdown } from 'react-bootstrap'



const Header = () => {

    const dispatch = useDispatch()
    const history = useNavigate();


    useEffect(() => {


        

    }, [])


    return (
        <header className="header">

            <Navbar expand="lg" collapseOnSelect style={{padding:'0px'}}>
                
                <Container>
                <LinkContainer to='/'>
                    <span>
                    <img className='tylogo'
                        src="/images/teachyaar.png"
                        height="60"
                        className="d-inline-block align-top"
                        alt="TeachYaar logo"
                    />

                    {/* <span className='h4' style={{marginTop:'20px'}}>Notebooks</span> */}

                    </span>
                
                </LinkContainer>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />


                <Navbar.Collapse id="basic-navbar-nav">

                <Nav className="ml-auto">

                <LinkContainer to='/smartdoubts'>
                    <Nav.Link className='navbarlink'>
                        
                        <Button style={{borderRadius:'20px', backgroundColor:"#0cc0df", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Snippets</Button>
                    </Nav.Link>
                </LinkContainer>

                {/* <LinkContainer to='/notebooks'>
                    <Nav.Link className='navbarlink'>
                        
                        <Button style={{borderRadius:'20px', backgroundColor:"#0cc0df", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Notebooks</Button>
                    </Nav.Link>
                </LinkContainer> */}

                {/* <LinkContainer to='/create'>
                    <Nav.Link className='navbarlink'>
                    <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Create</Button>
                        
                    </Nav.Link>
                </LinkContainer> */}

               

                

                


                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        </header>
    )
}

export default Header
