import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup, Badge } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


const JsonFileLoader = ({ dataCallBack}) => {

    const dispatch = useDispatch()

    let history = useNavigate();


    const [text, setText] = useState('')


    const addTextRequest = () => {
        
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
          return;
        }
    
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const json = JSON.parse(e.target.result);

            dataCallBack(json.title, json.cards)

          } catch (error) {
            console.error("Error reading or parsing file", error);
          }
        };
    
        reader.readAsText(file);
      };


    useEffect(() => {
 


    }, [])



    return (
        <>
            <input type="file" onChange={handleFileChange} accept="application/json" />
    
        </>
    )
}


export default JsonFileLoader
