import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title, description, keywords}) => {
    return (
        <Helmet>
            <title> {title}</title>
            <meta name='description' content={description}/>
            <meta name='keywords' content={keywords}/>
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'TeachYaar Notebook',
    description: 'Create Exams, Tests, Assignments, Quizzes, Surveys and Questionnaires in minutes. Share the exams by text, email or whatsapp or add them onto your online class,  webpage or youtube video with a link.',
    keywords: 'Whatsapp for learning, learning on whatsapp, exams using whatsapp, quizzes, mcqs, surveys, exams in a minute, multiple choice, practice tests,  K12, K-12, Term 1 Board, CBSE Class 12, CBSE Class 10, CBSE Board Revision, teaching platform, smart class, elearning, e-learning, e-teaching, teachers, tutors, classes, courses, digital education, online learning, virtual-classroom'
}

export default Meta
