import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import {searchRecentTopics} from '../../actions/smartlearnActions.js'

export const SearchConcepts = ({index, slcard}) => {

    const dispatch = useDispatch()

    const [subject, setSubject] = useState('english')

    const [searchstring, setSearchstring] = useState('')

    const submitSearchRequest = (e) => {
        e.preventDefault()

        dispatch(searchRecentTopics('string',searchstring))

    }

    const searchBySubject = (subject_in) => {

        dispatch(searchRecentTopics('subject', subject_in))
    }


    useEffect(() => {
        


        
    }, [])




    return (

   <>

    

    {/* <InputGroup>
        <Button className='card_badge'>English</Button> <Button className='card_badge'> Maths</Button><Button className='card_badge'>Physics</Button>
    </InputGroup> */}


   
   <Form onSubmit={submitSearchRequest} style={{marginBottom:'5px'}}>
        
        <InputGroup>
        <Form.Control type='text' placeholder='Search for a Concept' value={searchstring} onChange={(e) => setSearchstring(e.target.value)} style={{width:'5%'}}> 
        </Form.Control>

        <Button type='submit' variant='outline-success' className='p-2' style={{backgroundColor:'#0cc0df'}}>
            <i className="fa fa-search" aria-hidden="true" style={{color:'white'}} ></i>
        </Button>
        </InputGroup>
    </Form>
   
   
   </>
            


    )
}


export default SearchConcepts
