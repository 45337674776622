import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import YouTube from 'react-youtube';


import {updateVideoCard} from '../../../actions/cardActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const VideoItem = ({item_in}) => {

    const dispatch = useDispatch()

    const notebookR = useSelector(state => state.notebookR)
    const { sessionMode } = notebookR

    const [viewMode, setViewMode] = useState('view')
    const toggleViewMode = () => {
        console.log("View toggle", viewMode)

        if(viewMode == 'view'){
            setViewMode('edit')
            
        }else{
            setViewMode('view')
        }
    }

    const [videoUrl, setVideoUrl] = useState("")
    const [description, setDescription] = useState("")


    var opts = {
        height: '400px',
        width: '650px',
        playerVars: {
          
          autoplay: 0,
        },
    };

    if(isMobile){

        opts = {
            width: '250px',
            height: '150px',
            playerVars: {
              
              autoplay: 0,
            },
          };

    }

   

    const updateVideoItem = () => {

        setViewMode('view')
        // var newItem = item_in
        // newItem.url = videoUrl
        // newItem.description = description

        dispatch(updateVideoCard(item_in._id, videoUrl, description))
        

    }


    const onReady = (event) => {
        event.target.pauseVideo();
        console.log('On Ready')
    }

    const onStateChange = (event) => {
        console.log('On State Change')
    }

    const onPlay = (event) => {
        console.log('On Play : ',item_in.url)
        //trackView()
    }



    useEffect(() => {
        setVideoUrl(item_in.url)
        setDescription(item_in.description)

    }, [])


    return (

        <>
            

            {viewMode == 'view' ? (

                <>


                    <ListGroup>
                    {sessionMode == 'edit' && (

                        <ListGroup.Item>
                            <Badge style={{padding:'5px'}} onClick={()=>toggleViewMode()}><i className="fas fa-edit" ></i></Badge>
                            {/* <Badge style={{padding:'5px'}} onClick={()=>deleteItem()}><i className="fas fa-trash" ></i></Badge> */}
                        </ListGroup.Item>

                        )}
                        <ListGroup.Item>
                        <YouTube videoId={item_in.url} opts={opts} onReady={onReady} onStateChange={onStateChange} onPlay={onPlay}  />

                        </ListGroup.Item>
                        <ListGroup.Item>

                            <p className='h5'>{item_in.description}</p>
                        </ListGroup.Item>
                    </ListGroup>



                 

                </>

            ) : (

                <>

                    
                    <InputGroup>
                    <Form.Control type='text' placeholder='Youtube Video URL' value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>

                    <Badge>
                        <i className="fas fa-check" onClick={()=>updateVideoItem()}></i>
                    </Badge>
                    </InputGroup>

                    <Form.Control as='textarea' placeholder='Add a Video Description' value={description} onChange={(e) => setDescription(e.target.value)} style={{backgroundColor:'white'}}> 
                    </Form.Control>


                   

                    


                </>


            )}



                

                

            

        </>

    )
}


export default  VideoItem
