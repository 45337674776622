

import {

    SHELVES_LIST,
    SHELVES_FAIL,
    LOAD_NOTEBOOK,
    NOTEBOOK_FAIL

} from '../constants/learnConstants'


export const shelvesReducer = (state = { shelves: []}, action) => {

    switch(action.type){
        case SHELVES_LIST:
            return { loading: true, shelves:action.payload}
        
        case SHELVES_FAIL:
            return { loading: false, error: action.payload, shelves:state.shelves}
        
        default:
            return state
    }

}



export const shelfnotebookReducer = (state = { title:'',cards: []}, action) => {

    switch(action.type){

        case LOAD_NOTEBOOK:
            return { loading: true, cards:action.payload.cards, title:action.payload.title}
        
        case NOTEBOOK_FAIL:
            return { loading: false, error: action.payload, cards:state.cards }
        default:
            return state
    }

}
