import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup, Badge } from 'react-bootstrap'

import {  Link, useNavigate} from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


const DownloadNotebook = ({}) => {

    const dispatch = useDispatch()

    let history = useNavigate();

    var [filename, setFilename] = useState('')

    const cardList = useSelector(state => state.cardList)
    const { cards } = cardList

    const notebookR = useSelector(state => state.notebookR)
    const { title, sessionMode } = notebookR


    const onDownload = () => {

        var filename_out = title.trim().replaceAll(" ","")

        if(!filename_out || filename_out == ''){
            filename_out = 'TYNotebook'
        }

        var filename_out = filename_out + ".json"

        var lists = []

        for(var iL=0; iL<cards.length; iL++){
            var clist  = cards[iL]

            //get title

            //get card type

            //if note




            var items = []
            
            var newCard = null


            if(cards[iL].type == 'note'){

                

                for(var iD=0;iD<clist.items.length; iD++){
                    var cItem = {
                        "text":clist.items[iD].text,
                        "type":clist.items[iD].type,
                        "table":{}
                    }
                    items.push(cItem)
                }


                var cCard = {
                    "title":cards[iL].title,
                    "type":cards[iL].type,
                    "items":items
                }

                newCard = cCard
                items = []
            }
            


            //if test
            if(cards[iL].type == 'test'){

                var questions = []

                for(var iD=0;iD<clist.questions.length; iD++){
                    var cItem = clist.questions[iD]
                    questions.push(cItem)
                }


                var cCard = {
                    "title":cards[iL].title,
                    "type":cards[iL].type,
                    "questions":questions
                }

                newCard = cCard

            }

  

            //if video
            var newC = {
                "title": cards[iL].title,
                "type": cards[iL].type,
                "items":items,
                "description":[],
                "url":"",
                "source":""
            }

            if(cards[iL].type == 'video'){

                var cCard = {
                    "title":cards[iL].title,
                    "type":cards[iL].type,
                    "description":cards[iL].description,
                    "url":cards[iL].url,
                    "source":cards[iL].source
                }

                newCard = cCard

            }

            lists.push(newCard)
    
        }


        var exportJson = {
            "title":title,
            "cards":lists
        }


        
        const jsonString = JSON.stringify(exportJson, null, 2);
       
        const blob = new Blob([jsonString], { type: 'application/json' });
        
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = filename_out;
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link); 
        URL.revokeObjectURL(url); 


    }

    useEffect(() => {
 


    }, [])



    return (
        <>

            <Card>
                <Card.Header>
                    <h5>Download Notebook</h5>
                    
                    <InputGroup>
                        <Button onClick={()=>onDownload()} style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}}>.tynotebook</Button>
                    </InputGroup>

                </Card.Header>
            </Card>



        </>
        

    )
}


export default DownloadNotebook
