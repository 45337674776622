import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup } from 'react-bootstrap'

import Moment from 'react-moment';
import DateFormatted from '../DateFormatted'


import {listRecentTopics, generateSmartLearnCard} from '../../actions/smartlearnActions.js'


export const SmartLearnQuestion = ({question, requestType}) => {

    const dispatch = useDispatch()

    const [textQuery, setTextQuery] = useState('')


    const selectQuestion = (question_in) => {
        
        setTextQuery(question_in)

        console.log("Option in ", question_in)

        dispatch(generateSmartLearnCard(question_in, 'concept'))

    }



    useEffect(() => {
        


        
    }, [])




    return (

        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
            <Card.Header style={{border:'None', margin:'3px', borderRadius:'10px'}}>
                {question && question.map((option, index)=>(
                    <Button style={{padding:'5px', margin:'5px', borderRadius:'20px', backgroundColor:'#0cc0df'}} onClick={()=>selectQuestion(option.to)}>{option.question}</Button>
                ))}

            </Card.Header>
        </Card>
    )
}


export default SmartLearnQuestion
