// Author : Anil Variyar
// License : Proprietary
// Property of : Anil Variyar/ Pixagan Technologies Private Limited

import React from 'react'
//import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import { Container } from 'react-bootstrap'


import NotebookScreen from './screens/NotebookScreen'
import ModulesScreen from './screens/ModulesScreen'
import NotebookViewScreen from './screens/NotebookViewScreen'
import LandingScreen from './screens/LandingScreen'

import SmartLearnScreen from './screens/SmartLearnScreen'

import EulaScreen from './screens/EulaScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'


import Alert from './components/Alert'
import Header from './components/Header'
import Footer from './components/Footer'


const App = () => {
  return (

    <Router>
      <Header/>

      <Alert /> 
      <main className='py-3'>



        <Container fluid>

          <Routes>

          <Route path='/' element={<SmartLearnScreen />} exact />

          <Route path='/aboutNotebooks' element={<LandingScreen />} exact />

          {/* <Route path='/create' element={<NotebookScreen />} exact /> */}
          <Route path='/notebooks/:url' element={<NotebookViewScreen />} exact />

          <Route path='/notebooks' element={<ModulesScreen />} exact />

          <Route path='/smartdoubts' element={<SmartLearnScreen />} exact />
          <Route path='/smartdoubts/:concept_url' element={<SmartLearnScreen />} exact />

          <Route path='/privacypolicy' element={<PrivacyPolicyScreen />} />
          <Route path='/termsandconditions' element={<EulaScreen />} />

          <Route element={NotebookScreen} />

          </Routes>
        

        </Container>



        
      </main>
      <Footer />
    </Router>
  )
}

export default App


