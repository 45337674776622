import React , {useState, useEffect, Fragment } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, InputGroup, OverlayTrigger, Popover, Tooltip, Badge } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import ShelfView from '../components/ShelfView'


import {loadShelves, loadNotebookById} from '../actions/learnActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const ModulesScreen = ({}) => {


    // var course_id = match.params.course_id
    const {course_id}  = useParams();

    const [selectSession, setSelectedSession ] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate();


    const shelvesR = useSelector(state => state.shelvesR)
    const { shelves } = shelvesR

    var shelfs = [
        {
            "_id":"asd3dsasd",
            "title":'English'
        },
        {
            "_id":"asd3dsasd",
            "title":'English'
        }
    ]


    const loadNotebookRequest = (notebook_id) => {
        navigate(`/notebooks/${notebook_id}`)
        
    }

    useEffect(() => {

        dispatch(loadShelves())

    }, [])


    return (

        <>

            <Meta title={'Learn : Collection of TeachYaar Notebooks'} description={'Create Dynamic Interactive Course Modules and Course Notes for your lectures. Make the lessons interactive for your students.'}/>

            <Container>

            <Card style={{borderRadius:'20px'}}>

            <Card.Header style={{borderRadius:'20px'}}>
                <p className='h4' style={{textAlign:'center'}}>Checkout the growing collection of TY-Notebooks we have created on different topics</p>
            </Card.Header>

            </Card>



            {shelves && shelves.map((cshelf, index)=>(

                <section style={{padding:'10px'}}>

                <Card style={{margin:'10px', padding:'5px', border:'None'}}>
                    <Card.Header style={{border:'None', margin:'3px', borderRadius:'10px'}}>

                        <Badge style={{padding:'5px', marginBottom:'5px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>

                            <span className='h5' style={{color:'white'}}>{cshelf.title}</span>

                        </Badge>

                        <ListGroup>

                            {cshelf.covers && cshelf.covers.length == 0 && (
                                <ListGroup.Item>
                                    <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>
                            )}

                            {cshelf.covers && cshelf.covers.map((cov, index2)=>(
                                <ListGroup.Item onClick={()=>loadNotebookRequest(cov.url)}>
                                    {cov.title}
                                </ListGroup.Item>
                            ))}
                            

                        </ListGroup>



                    </Card.Header>            

                </Card>



                </section>





            ))}




            </Container>


          
  

        </>
    )
}


export default ModulesScreen
