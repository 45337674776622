import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  Row, Col } from 'react-bootstrap'
import {Nav  } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, ListGroup, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'


const Footer = () => {


    return (
        <footer className="footer">


            <Container>

                <hr />
            <Row className='show-grid'>

                {/* <Col></Col> */}

                <Col>
                <LinkContainer to='/termsandconditions'>
                    <Nav.Link>
                 Terms and Conditions
                    </Nav.Link>
            </LinkContainer>
                </Col>
                <Col>
                <LinkContainer to='/privacypolicy'>
                <Nav.Link> 
                     Privacy Policy 
                </Nav.Link>
            </LinkContainer>
                
                </Col>


            </Row>

            <hr/>

            <Row>

            <Col className='text-center py-3 mt-0'>
                &copy; 2021-2024, Pixagan&reg; Technologies Pvt. Limited. All rights reserved.  
            </Col>

                <Col className='text-center py-3 mt-0'>
        
                <Link onClick={()=>{window.open('https://www.facebook.com/TeachYaar-100362602539876')}}><i class="fab fa-facebook-f" style={{padding:'5px'}}></i></Link>
                <Link onClick={()=>{window.open('https://www.youtube.com/channel/UCbHYybvN5GM1z038VwNDtYQ')}}><i class="fab fa-youtube" style={{padding:'5px'}}></i></Link>
                {/* <i class="fab fa-twitter" style={{padding:'5px'}}></i> */}
                <Link onClick={()=>{window.open('https://www.linkedin.com/company/pixagan')}}><i class="fab fa-linkedin-in" style={{padding:'5px'}}></i></Link>
                <Link onClick={()=>{window.open('https://teachyaar.medium.com/')}}><i class="fab fa-medium" style={{padding:'5px'}}></i></Link>
                <Link onClick={()=>{window.open('https://www.instagram.com/teachyaar')}}><i class="fab fa-instagram" style={{padding:'5px'}}></i></Link>
            
                </Col>
            </Row>



                </Container>




        </footer>
    )
}

export default Footer










{/* 
                <Row>


                    <Col>
                    </Col>


                    <Col className='text-center py-3 mt-0'>
                    <Card>

                    <ListGroup variant="flush">
                    <ListGroup.Item>                
                        <LinkContainer to='/eula'>
                        <Nav.Link> EULA</Nav.Link>
                        </LinkContainer>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <LinkContainer to='/privacypolicy'>
                            <Nav.Link> Privacy Policy</Nav.Link>
                        </LinkContainer>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <LinkContainer to='/privacypolicy'>
                            <Nav.Link> Teacher Onboarding Agreement</Nav.Link>
                        </LinkContainer>
                    </ListGroup.Item>

                    </ListGroup>
                        
                    </Card>

                    </Col>


                    

                    <Col className='text-center py-3 mt-0'>


                    <Card>

                    <ListGroup variant="flush">
                    <ListGroup.Item><i class="fab fa-facebook-f"></i></ListGroup.Item>
                    <ListGroup.Item><i class="fab fa-youtube"></i></ListGroup.Item>
                    <ListGroup.Item><i class="fab fa-twitter"></i></ListGroup.Item>
                    <ListGroup.Item><i class="fab fa-linkedin-in"></i></ListGroup.Item>
                    </ListGroup>

                    </Card>

                    </Col>

                    <Col className='text-center py-3 mt-0'>


                <Card>

                <ListGroup variant="flush">


                <ListGroup.Item>
                    <LinkContainer to='/contactus'>
                        <Nav.Link> Contact Us Form</Nav.Link>
                    </LinkContainer>
                </ListGroup.Item>


                <ListGroup.Item>
                    <LinkContainer to='/contactus'>
                        <Nav.Link> 39/2475-B1, Suite#244
                        LR Towers, SJRRA 104,
                        Palarivattom,
                        Kochi, Kerala, 682025
                        </Nav.Link>
                    </LinkContainer>
                </ListGroup.Item>

                <ListGroup.Item>
                    <LinkContainer to='/contactus'>
                        <Nav.Link> support@teachyaar.com
                        </Nav.Link>
                    </LinkContainer>
                </ListGroup.Item>



                </ListGroup>
                    
                </Card>

                </Col>

                    <Col></Col>


                </Row> */}



