import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, InputGroup, OverlayTrigger, Popover, Tooltip, Badge, Table } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import PageTitle from '../components/cards/PageTitle'
import AddCardForm from '../components/cards/AddCardForm'
import EditCard from '../components/cards/EditCard'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const LandingScreen = ({match, history}) => {


    const dispatch = useDispatch()



    useEffect(() => {



    }, [])



    return (

        <>

            <Meta title={'TeachYaar'} description={'Create Dynamic Interactive Course Modules and Course Notes for your lectures. Make the lessons interactive for your students.'}/>

       

                <section style={{backgroundColor:'#f0f2f2'}}> 
                    <p className='h1' style={{textAlign:'center'}}>Create Interactive, Dynamic, Shareable Learning Notebooks</p>

                </section>


            <section className='landing_section' >
                
               

                        {isMobile ? (
                            <p className='h4' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>Make your course content smart</p>
                    
                        ): (
                            <span className='h3' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>Make your course content smart</span>
                    
                        )}

                        

                       

                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

                            {isMobile ? (
                                <img src="TeachYaar.jpg" alt="TeachYaar Example" style={{width:'90vw'}}/>
                            ) : (
                                <img src="TeachYaar.jpg" alt="TeachYaar Example" style={{height:'50vh'}}/>
                            )}


                            </div>



                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                
                    <Link to='/create'>
                        <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center'}}>Get started Now for Free</Button>
                    </Link>

                </div>
                
                
                

            </section>

            <section className='landing_section' style={{backgroundColor:'#f0f2f2'}}>

                        <Container>



                        {isMobile ? (
                            <p className='h4' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>Integrate different types of Content into your TY-Notebooks</p>
                    
                        ): (
                            <span className='h3' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>Integrate different types of Content into your TY-Notebooks</span>
                    
                        )}

        <Row>
            <Col style={{padding:'20px'}}>
                <ListGroup>
                <ListGroup.Item style={{padding:'20px'}}>Text Notes</ListGroup.Item>
                <ListGroup.Item style={{padding:'20px'}}> Equations</ListGroup.Item>
                <ListGroup.Item style={{padding:'20px'}}>Lists</ListGroup.Item>
                <ListGroup.Item style={{padding:'20px'}}>Tables</ListGroup.Item>
                </ListGroup>
                
                
            </Col>
            <Col style={{padding:'20px'}}>
                <ListGroup>
                <ListGroup.Item style={{padding:'20px'}}><span>Plots</span><span style={{color:'#8b008b'}}> (Coming Soon)</span> </ListGroup.Item>
                <ListGroup.Item style={{padding:'20px'}}><span>Diagrams</span><span style={{color:'#8b008b'}}> (Coming Soon)</span> </ListGroup.Item>
                <ListGroup.Item style={{padding:'20px'}}><span>Images</span><span style={{color:'#8b008b'}}> (Coming Soon)</span> </ListGroup.Item>
                <ListGroup.Item style={{padding:'20px'}}><span>Videos</span></ListGroup.Item>
                </ListGroup>
                
            </Col>

        </Row>


            </Container>
             
                        

                      
                

            </section>

        

            <section className='landing_section' >

                <Container>

                <Link to='/notebooks'>
                    

                    {isMobile ? (
                            <p className='h4' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>Checkout our growing collection of TY-Notebooks on a variety of Topics</p>
                    
                        ): (
                            <span className='h3' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>Checkout our growing collection of TY-Notebooks on a variety of Topics</span>
                    
                        )}

                    
                    </Link>
                        
                        <Row>
                            <Col style={{padding:'20px'}}>
                            <ListGroup>
                            <ListGroup.Item>
                                Basics of Nouns  <Button style={{borderRadius:'20px', backgroundColor:"white", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Intro to Complex Numbers   <Button style={{borderRadius:'20px', backgroundColor:"white", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Basics of Statistics | <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>

                        </ListGroup>
                            
                            </Col>
                            <Col style={{padding:'20px'}}>
                            <ListGroup>
                            <ListGroup.Item>
                                Getting started with Python | <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                What is Linear Regression | <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Introduction to Deep Learning | <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px'}}>Coming Soon</Button>
                            </ListGroup.Item>

                        </ListGroup>
                            
                            </Col>
                        </Row>
                        


                </Container>

                    


            </section>


            {/* <section className='landing_section' style={{backgroundColor:'#f0f2f2'}}>

                <Container>

            

                    {isMobile ? (
                            <p className='h4' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>TeachYaar's Smart-Doubt platform makes doubt clearing easy and fun </p>
                    
                        ): (
                            <span className='h3' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>TeachYaar's Smart-Doubt platform makes doubt clearing easy and fun</span>
                    
                        )}


                    

                    <div>
                    <p className='h5' style={{marginTop:'10px'}}>The Smart-Doubt tool is designed to make integration with AI-LLM models extremly easy.</p>
                    <Link to='/smartdoubts'>
                        <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center'}}>Try it out now</Button>
                    </Link>
                    </div>
                    

                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

                    {isMobile ? (
                        <img src="SmartDoubtTool.png" alt="Smart Doubt Tool" style={{width:'90vw'}}/>
                    ) : (
                        <img src="SmartDoubtTool.png" alt="Smart Doubt Tool" style={{height:'50vh'}}/>
                    )}


                    </div>

                    
                    
                        


                </Container>

                    


            </section> */}


            <section className='landing_section' style={{backgroundColor:'white'}}>

        
                    <Container>

                    {/* <Badge style={{padding:'5px', marginBottom:'5px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>
            
                        <span className='h3' style={{color:'white'}}>FAQs</span>

                    </Badge> */}

                    {isMobile ? (
                            <p className='h4' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>FAQs</p>
                    
                        ): (
                            <span className='h3' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>FAQs</span>
                    
                        )}

                           

        


            <Row>
            <Col style={{padding:'20px'}}>

            <ListGroup>
            
                <ListGroup.Item>
                    <p className='h5'>Q: Can I use TY-Notebooks as a pdf replacement?</p>
                    <p className='h5'>A: Yes, the notebooks are a perfect substitute for pdf based course notes.</p>
                
                </ListGroup.Item>
                <ListGroup.Item>
                    <p className='h5'>Q: What features are free?</p>
                    <p className='h5'>A: The current set of features for creation are free.</p>
                </ListGroup.Item>
                <ListGroup.Item>
                    
                </ListGroup.Item>

        </ListGroup>
            
            </Col>

            </Row>

        






                    </Container>

                   
                

            </section>




        </>
    )
}


export default LandingScreen
