import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import DownloadNotebook from './io/DownloadNotebook'
import UploadNotebook from './io/UploadNotebook'

import {clearSession, updateSessionMode} from '../actions/sessionActions'

export const MainMenu = ({}) => {

    const dispatch = useDispatch()

    const notebookR = useSelector(state => state.notebookR)
    const { title, sessionMode } = notebookR

    const [text, setText] = useState('')

    const [viewMode, setViewMode] = useState("")
    const updateViewMode = (viewMode_in) => {
        if(viewMode_in == viewMode){
            setViewMode("")
        }else{
            setViewMode(viewMode_in)
        }
    }


    const clearSessionRequest = () => {
        dispatch(clearSession())
    }


    const updateSessionModeRequest = (mode_in) => {

        dispatch(updateSessionMode(mode_in))
        
    }
    
    useEffect(() => {
        

    }, [])



    return (

        <div style={{marginBottom:'5px'}}>

        <InputGroup>
            <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}} onClick={()=>updateViewMode("session")}>View</Button>
            <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}} onClick={()=>updateViewMode("import")}>Import</Button>
            <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}} onClick={()=>updateViewMode("export")}>Export</Button>
            <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}} onClick={()=>updateViewMode("clear")}>Clear</Button>
        </InputGroup>


        {viewMode == 'import' && (
            <UploadNotebook />
        )}


        {viewMode == 'export' && (
            <DownloadNotebook />
        )}

        {viewMode == 'session' && (
            <>
                <InputGroup style={{marginTop:'5px'}}>
                    <Button onClick={()=>updateSessionModeRequest('view')} style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:sessionMode=='view'?'#147eb3' : '#8c8d8f'}}>View</Button>
                    <Button onClick={()=>updateSessionModeRequest('edit')} style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:sessionMode=='edit'?'#147eb3' : '#8c8d8f'}}>Edit</Button>
                </InputGroup>
            </>
        )}

        {viewMode == 'clear' && (
            <>
                <Card>
                    <p>Are you sure you want to clear the session? All cards will be deleted from the browser.</p>
                    <InputGroup>
                        <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}} onClick={()=>clearSessionRequest()}>Yes, Clear the Session</Button>
                        <Button  style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#8c8d8f'}} onClick={()=>setViewMode('')}>No, Don't clear the session</Button>
                    </InputGroup>
                </Card>
            </>
        )}  
            
                        
        </div>

    )
}


export default MainMenu
