import axios from 'axios';
import { setAlert } from './alertActions';

import {

    UPDATE_SESSION_TITLE,
    SESSION_TITLE_FAIL,
    SESSION_VIEW_MODE,
    CLEAR_SESSION,
    CLEAR_CARDS,

    CARD_LIST,
    CARDS_FAIL

} from '../constants/cardConstants'




export const updateTitle = ( title_in ) => async (dispatch, getState) => {

    try{

        
        console.log("Update Title")

        dispatch({
            type: UPDATE_SESSION_TITLE,
            payload: title_in
        })

    }catch (error){

        dispatch({
            type: SESSION_TITLE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const updateSessionMode = ( viewMode_in ) => async (dispatch, getState) => {

    try{

        
        console.log("Update View Mode")

        dispatch({
            type: SESSION_VIEW_MODE,
            payload:viewMode_in
        })

    }catch (error){

        dispatch({
            type: SESSION_TITLE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}


export const clearSession = ( ) => async (dispatch, getState) => {

    try{

        
        console.log("Clear Session")

        dispatch({
            type: CLEAR_SESSION,
        })

        dispatch({
            type: CLEAR_CARDS,
        })

    }catch (error){

        dispatch({
            type: SESSION_TITLE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




export const uploadSession = ( title, cards ) => async (dispatch, getState) => {

    try{

        
        console.log("Update Title")

        dispatch({
            type: SESSION_VIEW_MODE,
            payload:'view'
        })

        dispatch({
            type: UPDATE_SESSION_TITLE,
            payload: title
        })


        dispatch({
            type: CARD_LIST,
            payload: cards
        })

    }catch (error){

        dispatch({
            type: SESSION_TITLE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}
