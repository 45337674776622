import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup, Badge } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import JsonFileLoader from './JsonFileLoader'

import {uploadSession} from '../../actions/sessionActions'

const UploadNotebook = ({}) => {

    const dispatch = useDispatch()

    let history = useNavigate();


    const [text, setText] = useState('')


    const onUploadFile = (title, cards) => {
        console.log("upload ", title, cards)
        dispatch(uploadSession(title, cards))
    };


    useEffect(() => {
 


    }, [])



    return (
        <>

            <JsonFileLoader dataCallBack={onUploadFile}/>

            
        </>
    )
}


export default UploadNotebook
