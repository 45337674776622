export const Qs_LIST  = 'Qs_LIST'
export const Qs_FAIL  = 'Qs_FAIL';
export const Qs_CLEAR  = 'Qs_CLEAR';

export const Qs_TAKE  = 'Qs_TAKE'

export const Qs_ADD  = 'Qs_ADD'
export const Qs_UPDATE  = 'Qs_UPDATE'
export const Qs_DELETE  = 'Qs_DELETE'
export const Qs_SOLUTION_ADD  = 'Qs_SOLUTION_ADD'
export const Qs_SOLUTION_DELETE  = 'Qs_SOLUTION_DELETE'
export const Qs_LEARNING_ADD  = 'Qs_LEARNING_ADD'
export const Qs_LEARNING_DELETE  = 'Qs_LEARNING_DELETE'

export const Qs_SOLUTION_BYID  = 'Qs_SOLUTION_BYID'

export const Qs_POST  = 'Qs_POST'

export const Qs_SUBMIT_ANSWER  = 'Qs_SUBMIT_ANSWER'
export const Qs_SUBMIT_ANSWER_SL  = 'Qs_SUBMIT_ANSWER_SL'

export const Qs_ANALYTICS  = 'Qs_ANALYTICS'
export const Qs_ANALYTICS_FAIL  = 'Qs_ANALYTICS_FAIL'
export const Qs_ANALYTICS_CLEAR  = 'Qs_ANALYTICS_CLEAR'

export const Qs_SUBMIT_ANSWER_TEST = 'Qs_SUBMIT_ANSWER_TEST'