import axios from 'axios';

import {


    Qs_LIST,
    Qs_FAIL,
    Qs_CLEAR,
    Qs_TAKE,
    Qs_ADD,
    Qs_UPDATE,
    Qs_DELETE,
    Qs_SOLUTION_DELETE,
    Qs_SOLUTION_ADD,
    Qs_LEARNING_ADD,
    Qs_LEARNING_DELETE,
    Qs_POST,

    Qs_ANALYTICS,
    Qs_ANALYTICS_FAIL,
    Qs_ANALYTICS_CLEAR,

    Qs_SUBMIT_ANSWER,

    Qs_SOLUTION_BYID


} from '../constants/qsConstants'




export const qsListReducer = (state = { qslist:[], pages:1, page:1, count:1 }, action) => {


    switch(action.type){
        case Qs_LIST:
            return { loading: false, success: true, qslist: action.payload.funqqs, page:action.payload.page, pages:action.payload.pages, count:action.payload.nQs }
        case Qs_ADD:
            return { loading: false, success: true, qslist:[action.payload, ...state.qslist] }
        case Qs_UPDATE:
            return { loading: false, success: true,                 
                qslist: state.qslist.map(qs => qs._id === action.payload._id ? { ...qs, Qtext:action.payload.Qtext, options:action.payload.options, learning: action.payload.learning}:qs), }
        case Qs_DELETE:
            return { loading: false, success: true,
                qslist: state.qslist.filter(qs => qs._id !== action.payload) }
            
        case Qs_FAIL:
            return { loading: false, error: action.payload, qslist: state.qslist }
        
       case Qs_POST:
        return { loading: false, success: true,                 
            qslist: state.qslist.map(qs => qs._id === action.payload._id ? { ...qs, isPost:action.payload.isPost}:qs), }

        case Qs_CLEAR:
            return {  }

        case Qs_SUBMIT_ANSWER:
            return { loading: false, success: true,                 
                qslist: state.qslist.map(qs => qs._id === action.payload._id ? { ...qs, answer:action.payload.submitted, correct:action.payload.correct, isAnswered:true, learn:action.payload.learn}:qs), 
                page: state.page,
                pages: state.pages,
                count: state.count
            }

        case Qs_LEARNING_ADD:
            return { loading: false, success: true,                 
                qslist: state.qslist.map(qs => qs._id === action.payload._id ? { ...qs, solution:action.payload.solution, learning:action.payload.learning}:qs), }


        default:
            return state
    }


}


export const qsByIdReducer = (state = { qsolution:null}, action) => {

    switch(action.type){

        case Qs_SOLUTION_BYID:
            return{loading:false, qsolution:action.payload}

        // case Qs_LIST_CARD:
        //     return { loading: false, success: true, qandacardlist: action.payload }
        // case Qs_LIST_FAIL:
        //     return { loading: false, error: action.payload }
        // case Qs_LIST_CLEAR:
        //     return {  }
        default:
            return state
    }


}