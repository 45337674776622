import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';


import VideoItem from './items/VideoItem'


import TestCardView from './viewOnly/TestCardView'
import VideoCardView from './viewOnly/VideoCardView'

import TextItemMenu from './items/TextItemMenu'

//import MathJax from 'react-mathjax';


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const ViewCard = ({card_in}) => {

    const dispatch = useDispatch()



    useEffect(() => {
        


        
    }, [])



    return (

        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
            <Card.Header style={{border:'None', margin:'3px', borderRadius:'10px'}}>

               
                {/* <Badge style={{padding:'5px', marginBottom:'5px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>
                    <p className='h5' style={{color:'white'}}>{card_in.title} </p>
                </Badge> */}

                {isMobile ? (
                            <p className='h5' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>{card_in.title}</p>
                    
                    ): (
                        <span className='h5' style={{textAlign:'center', padding:'5px', marginBottom:'10px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>{card_in.title}</span>
                
                    )}




                {card_in.type == 'note' && (
                    <>
                       <ListGroup>
                        {card_in.items && card_in.items.map((citem, index)=>(
                            <ListGroup.Item>

                                {citem.type == 'text' && (
                                    <p>{citem.text}</p>
                                )}

                                
                                {/* {citem.type == 'equation' && (
                                   <MathJax.Provider>
                                        <div style={{display:'flex', justifyContent:'left'}}>
                                            <MathJax.Node formula={citem.text}/>
                                        </div>
                                    </MathJax.Provider>
                                )} */}

                                {citem.type == 'table' && (
                                    <></>
                                )}

                                {citem.type == 'list' && (
                                    <ul>
                                    {citem.text.split("|").map((sitem, index)=>(
                                        <li>{sitem}</li>
                                    ))}
                                    </ul>
                                )}

                                {citem.type == 'code' && (
                                    <p>{citem.text}</p>
                                )}

                            
                            </ListGroup.Item>
                        ))}

                    </ListGroup>

                    </>
                )}

                

                {card_in.type == 'test' && (
                    <>
                        <TestCardView card_in={card_in}/>
                    </>
                )}


                {card_in.type == 'video' && (
                    <>
                        <VideoCardView item_in={card_in}/>    
                    </>
                )}



            </Card.Header>            

        </Card>
    )
}


export default ViewCard
