export const SLCARD_LIST           = 'SLCARD_LIST';
export const SLCARD_LIST_FAIL      = 'SLCARD_LIST_FAIL';
export const SLCARD_LIST_GENERATE  = 'SLCARD_LIST_GENERATE';

export const SLCARD_EXAMPLE_GENERATE  = 'SLCARD_EXAMPLE_GENERATE';
export const SLCARD_EXAMPLE_FAIL  = 'SLCARD_EXAMPLE_FAIL';

export const SLCARD_TEST_GENERATE  = 'SLCARD_TEST_GENERATE';
export const SLCARD_TEST_FAIL  = 'SLCARD_TEST_FAIL';

export const SLCARD_NAV        = 'SLCARD_NAV';
export const SLCARD_NAV_FAIL   = 'SLCARD_NAV_FAIL';

export const SLTOPIC_LIST          = 'SLTOPIC_LIST';
export const SLTOPIC_LIST_FAIL     = 'SLTOPIC_LIST_FAIL';

export const UPDATE_LEARNING_PATH = 'UPDATE_LEARNING_PATH'
export const LEARNING_PATH_FAIL   = 'LEARNING_PATH_FAIL'
export const CLEAR_LEARNING_PATH  = 'CLEAR_LEARNING_PATH'

export const SEARCH_DOUBT        = 'SEARCH_DOUBT';
export const SEARCH_DOUBT_FAIL   = 'SEARCH_DOUBT_FAIL';