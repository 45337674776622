import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';
import DateFormatted from '../DateFormatted'


import {listRecentTopics, generateSmartLearnCard, generateExampleSLCard, generateVideoSLCard} from '../../actions/smartlearnActions.js'

import QsItemTake from './Qs/QsItemTake'

import ImageCanvas from './ImageCanvas'
//import MathJax from 'react-mathjax';
import SLTest from './SLTest.js'
import SLVideo from './SLVideo.js'

export const SmartLearnCard = ({index, slcard}) => {

    const dispatch = useDispatch()


    const [tagC, setTagC ] = useState('')

    const [textQuery, setTextQuery ] = useState('')

    const [addTagtoggle, setaddTagtoggle ] = useState(false)
    const toggleAddTag = () => {
        setaddTagtoggle(addTagtoggle => !addTagtoggle)
    }



    const selectQuestion = (question_in) => {
        
        setTextQuery(question_in)

        dispatch(generateSmartLearnCard(question_in))

    }

    const requestExample = () => {
        dispatch(generateExampleSLCard(slcard.hashtag))
    }


    const requestVideo = () => {
        dispatch(generateVideoSLCard(slcard.hashtag))
    }


    const requestTest = () => {
        dispatch(generateSmartLearnCard(slcard.hashtag, 'test'))
    }



    useEffect(() => {
        


        
    }, [])




    return (

        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
            <Card.Header style={{border:'solid', margin:'3px', borderRadius:'10px'}}>


            {/* <Badge style={{padding:'5px', marginBottom:'5px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}>{slcard.title}</Badge> */}

            {/* <Badge style={{padding:'5px', marginBottom:'5px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}><span className='h5' style={{color:'white'}}>{slcard.title}</span></Badge> */}

            <div>
                {/* <div style={{padding:'5px', marginBottom:'5px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}}> 
                <span className='h5' style={{color:'white'}}>{slcard.title}</span>
                </div> */}
                <Badge className='card_badge' style={{padding:'5px', marginBottom:'5px', color:'white', borderRadius:'5px'}} className='card_badge'><span className='h5' style={{color:'white'}}>{slcard.title}</span></Badge>
            </div>
            

            <ListGroup>
            {slcard && slcard.items && slcard.items.map((item, index)=>(
                <ListGroup.Item style={{border:'None', backgroundColor:'inherit', padding:'2px', margin:'2px'}}>
                {item.type == 'text' && (
                    <p className='h5'>{item.text}</p>
                )}

                {item.type == 'equation' && (
                    <>
                    {/* <MathJax.Provider>
                     <div>
                            <MathJax.Node formula={item.equation} />
                         </div>
                    </MathJax.Provider> */}
                    </>
                )}
                {item.type == 'list' && (
                    <ul>
                    {item.list && item.list.split(';').map((it, index)=>(
                        <li>{it}</li>
                    ))}

                    </ul>
                )}

                {item.type == 'svg' && item.svg && item.svg.opers && (

                    <ImageCanvas operationlist={item.svg}/>
             
                )}
                
                </ListGroup.Item>
            ))}

            </ListGroup>




                {slcard && slcard.example && (
                    <QsItemTake question={slcard.example} index={0} mode_in='SL' card_id={slcard.id}/>
                )}


                {slcard && slcard.qs && (
                    <SLTest questions={slcard.qs} card_id={slcard.id}/>
                )}


                {slcard && slcard.video && (
                    <SLVideo video={slcard.video} />
                )}


            <div style={{display:'flex'}}>

            {/* {slcard && slcard.practice > 0 && ( */}
                <ListGroup horizontal style={{marginLeft:'auto'}}>
                    {slcard.isExample && (
                <ListGroup.Item style={{backgroundColor:'rgb(238, 213, 30)', marginLeft: "auto", color:'white', padding:'3px', paddingLeft:'9px', paddingRight:'9px', margin:'2px', borderRadius:'10px', paddingLeft:'9px', paddingRight:'9px', color:'black'}} onClick={requestExample}> Try an Example</ListGroup.Item>
                    )}

                {slcard.isVideo && (
                <ListGroup.Item style={{backgroundColor:'rgb(238, 213, 30)', marginLeft: "auto", color:'white', padding:'3px', paddingLeft:'9px', paddingRight:'9px', margin:'2px', borderRadius:'10px', paddingLeft:'9px', paddingRight:'9px', color:'black'}} onClick={requestVideo}> Video</ListGroup.Item>
                )}

                {/* <ListGroup.Item style={{backgroundColor:'#00416a', marginLeft: "auto", color:'white', padding:'3px',margin:'2px', borderRadius:'10px', paddingLeft:'9px', paddingRight:'9px'}} onClick={requestTest}> Take a Test</ListGroup.Item> */}
                {/* <ListGroup.Item style={{backgroundColor:'#00416a', marginLeft: "auto", color:'white', padding:'3px',margin:'2px', borderRadius:'10px', paddingLeft:'9px', paddingRight:'9px'}} onClick={requestExample}> Calculator</ListGroup.Item> */}
                </ListGroup>
            {/* )} */}

            </div>



            </Card.Header>

            

        </Card>
    )
}


export default SmartLearnCard
