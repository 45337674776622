import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';

import { v4 as uuidv4 } from 'uuid';

import {addQuestionTest} from '../../../actions/cardActions'


export const QuestionItemMenu = ({card_id}) => {

    const dispatch = useDispatch()

    const [text, setText] = useState('')
    const [questionType, setQuestionType] = useState('mcq')
    const [nOptions, setnOptions] = useState(4)
    const [options, setOptions] = useState({
        '0':'',
        '1':'',
        '2':'',
        '3':'',
        '4':'',
        '5':'',
        '6':'' 
    })

    const [option1, setOption1] = useState("")
    const [option2, setOption2] = useState("")
    const [option3, setOption3] = useState("")
    const [option4, setOption4] = useState("")
    const [option5, setOption5] = useState("")
    const [option6, setOption6] = useState("")

    const [textAnswer, setTextAnswer] = useState("")
    const [mcqAnswer, setMCQAnswer] = useState("")
    const [testMode, setTestMode] = useState("")

    const updateOptions = (index, value) => {
        var opts = options
        opts[index] = value
        setOptions(opts)

    }


    const [viewMode, setViewMode] = useState('view')
    const toggleViewMode = () => {
        
    }

    const [itemType, setItemType] = useState('')




    const addQuestionRequest = () => {

        var question = {
            "_id":uuidv4().toString(),
            "questionType":questionType,
            "nOptions":nOptions,
            "question":[text],
            "options":[option1, option2, option3, option4, option5, option6],
            "answerMCQ":mcqAnswer,
            "answerText":textAnswer
        }

        dispatch(addQuestionTest(card_id, question))

    }

    useEffect(() => {
        

    }, [])



    return (

        <>
            <InputGroup>
            <Form.Control type='text' placeholder='text' value={text} onChange={(e) => setText(e.target.value)} style={{backgroundColor:'white'}}> 
            </Form.Control>
            <Badge>
                <i className="fas fa-check" onClick={()=>addQuestionRequest()}></i>
            </Badge>
            </InputGroup>

            <Row>
          <Col>
          <Form.Control as='select' value={questionType} onChange={(e) => setQuestionType(e.target.value)} style={{margin:'5px'}}> 
            <option>Question Types</option>
            <option value="mcq">MCQ</option>
            <option value="text">Text</option>
            </Form.Control>
          </Col>


          <Col>

            {questionType == 'mcq' && (

                <Form.Control as='select' value={nOptions} onChange={(e) => setnOptions(e.target.value)} style={{margin:'5px'}}> 
                <option>N Options</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                </Form.Control>

            )}

          
          </Col>


        </Row>

        {questionType == 'mcq' && (
            <Row>

                
                <Col style={{minWidth:'40vw'}}>

                    <span>a) </span>
                    <Form.Control type='text' placeholder='Option 1' value={option1} onChange={(e) => setOption1(e.target.value)} style={{margin:'5px'}}> 
                    </Form.Control>

                </Col>

                <Col style={{minWidth:'40vw'}}>
                    <span>b) </span>
                    <Form.Control type='text' placeholder='Option 2' value={option2} onChange={(e) => setOption2(e.target.value)} style={{margin:'5px'}}> 
                    </Form.Control>

                </Col>


                {parseInt(nOptions) >2 && (
                    <Col style={{minWidth:'40vw'}}>
                        <span>c) </span>
                        <Form.Control type='text' placeholder='Option 3' value={option3} onChange={(e) => setOption3(e.target.value)} style={{margin:'5px'}}> 
                        </Form.Control>

                        </Col>
                )}
                

                {parseInt(nOptions) >3 && (
                    
                    <Col style={{minWidth:'40vw'}}>
                        <span>d) </span>
                    <Form.Control type='text' placeholder='Option 4' value={option4} onChange={(e) => setOption4(e.target.value)} style={{margin:'5px'}}> 
                    </Form.Control>

                    </Col>


                    )}

                

                {parseInt(nOptions) >4 && (
                
                <Col style={{minWidth:'40vw'}}>
                    <span>e) </span>
                    <Form.Control type='text' placeholder='Option 5' value={option5} onChange={(e) => setOption5(e.target.value)} style={{margin:'5px'}}> 
                    </Form.Control>

                </Col>

                )}

                

                {parseInt(nOptions) >5 && (
                
                <Col style={{minWidth:'40vw'}}>
                    <span>f) </span>
                
                    <Form.Control type='text' placeholder='Option 6' value={option6} onChange={(e) => setOption6(e.target.value)} style={{margin:'5px'}}> 
                    </Form.Control>

                </Col>

                )}

                

                </Row>
        )}

        

            <hr />


            {questionType == 'mcq' ? (

                <Form.Control as='select' value={mcqAnswer} onChange={(e) => setMCQAnswer(e.target.value)} style={{margin:'5px'}}> 
                <option value="-1">Answer</option>
                <option value="1">a</option>
                <option value="2">b</option>
                <option value="3">c</option>
                <option value="4">d</option>
                <option value="5">e</option>
                <option value="6">f</option>
                </Form.Control>

            ) : (
                
                <>      
                    <Form.Control as='textarea' placeholder='Answer' value={textAnswer} onChange={(e) => setTextAnswer(e.target.value)} style={{margin:'5px'}}> 
                    </Form.Control>
                </>
                

            )}

            

            
                        
        </>

    )
}


export default QuestionItemMenu
